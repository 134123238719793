.blink {
  animation: blink normal 2s infinite ease-in-out;
  -webkit-animation: blink normal 2s infinite ease-in-out;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.spin {
  animation: spin normal 0.5s infinite ease-in-out;
  -webkit-animation: spin normal 0.5s infinite ease-in-out;
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
